// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-get-involved-donate-gift-aid-thanks-js": () => import("./../src/pages/get-involved/donate/gift-aid/thanks.js" /* webpackChunkName: "component---src-pages-get-involved-donate-gift-aid-thanks-js" */),
  "component---src-pages-get-involved-donate-thank-you-js": () => import("./../src/pages/get-involved/donate/thank-you.js" /* webpackChunkName: "component---src-pages-get-involved-donate-thank-you-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-events-page-js": () => import("./../src/templates/events-page.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-features-page-js": () => import("./../src/templates/features-page.js" /* webpackChunkName: "component---src-templates-features-page-js" */),
  "component---src-templates-general-page-js": () => import("./../src/templates/general-page.js" /* webpackChunkName: "component---src-templates-general-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-stories-page-js": () => import("./../src/templates/stories-page.js" /* webpackChunkName: "component---src-templates-stories-page-js" */)
}

